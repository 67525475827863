import React from 'react';
import { Handle, Position } from 'react-flow-renderer';

import orbLogo from '../icons/orb_logo_white.png';
import class0 from '../icons/class0.png';
import class1 from '../icons/class1.png';
import class2 from '../icons/class2.png';
import class3 from '../icons/class3.png';
import class4 from '../icons/class4.png';
import class5 from '../icons/class5.png';

const CustomNode = ({ data }) => {
    const label = data.label.props.children[0];
    const dimensionType = data.dimensionType;
    const averageClass = data.averageClass;
    const isFrontrooms = typeof label === 'string' && label.includes('Frontrooms');

    let borderStyle = 'none';

    if (dimensionType === 1) {
        borderStyle = '1px solid white';
    } else if (dimensionType === 2) {
        borderStyle = '1px solid rgb(64, 48, 186)';
    } else if (dimensionType === 3) {
        borderStyle = '1px solid rgb(186, 48, 48)';
    } else if (dimensionType === 4) {
        borderStyle = '1px solid rgb(44, 122, 65)';
    }

    let currentAverageClass = orbLogo;

    switch (averageClass) {
        case 0:
            currentAverageClass = class0;
            break;
        case 1:
            currentAverageClass = class1;
            break;
        case 2:
            currentAverageClass = class2;
            break;
        case 3:
            currentAverageClass = class3;
            break;
        case 4:
            currentAverageClass = class4;
            break;
        case 5:
            currentAverageClass = class5;
            break;
        default:
            currentAverageClass = orbLogo;
            break;
    }

    return (
        <div
            style={{
                width: 200,
                height: 100,
                backgroundColor: isFrontrooms ? 'rgba(200, 255, 168, 0.1)' : 'rgba(255, 255, 255, 0.1)',
                color: 'white',
                borderRadius: '10px',
                border: borderStyle,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px',
                boxSizing: 'border-box',
                position: 'relative'
            }}>
            <Handle type="source" position={Position.Top} style={{ opacity: 0 }} />
            <Handle type="source" position={Position.Bottom} style={{ opacity: 0 }} />
            <Handle type="source" position={Position.Left} style={{ opacity: 0 }} />
            <Handle type="source" position={Position.Right} style={{ opacity: 0 }} />
            <Handle type="target" position={Position.Top} style={{ opacity: 0 }} />
            <Handle type="target" position={Position.Bottom} style={{ opacity: 0 }} />
            <Handle type="target" position={Position.Left} style={{ opacity: 0 }} />
            <Handle type="target" position={Position.Right} style={{ opacity: 0 }} />
            {data.label}
            <img
                src={currentAverageClass}
                alt="Logo de la classe de la dimension"
                style={{
                    position: 'absolute',
                    bottom: 5,
                    right: 5,
                    width: '20px',
                    height: '20px'
                }}
            />
        </div>
    );
};

export default CustomNode;