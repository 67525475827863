import React from 'react';
import { useLanguage } from '../context/LanguageContext';
import translations from '../locales/translations.json';

export default function Legend() {
    const { language } = useLanguage();

    const legendItems = [
        { color: 'rgba(44, 122, 65, 0.5)', text: translations.legend.frontrooms[language] },
        { color: 'rgba(255, 255, 255, 0.5)', text: translations.legend.dimensions[language] },
        { color: 'rgba(0, 0, 255, 0.5)', text: translations.legend.subDimensions[language] },
        { color: 'rgba(255, 0, 0, 0.5)', text: translations.legend.abnormalDimensions[language] },
    ];

    return (
        <div className="legend">
            {legendItems.map((item, index) => (
                <div key={index} className="legend-item">
                    <span className="legend-color" style={{ backgroundColor: item.color }}></span>
                    <span className="legend-text">{item.text}</span>
                </div>
            ))}
        </div>
    );
}