import React, { useEffect, useState } from 'react';
import orbLogo from '../icons/orb_logo_white.png';
import class0 from '../icons/class0.png';
import class1 from '../icons/class1.png';
import class2 from '../icons/class2.png';
import class3 from '../icons/class3.png';
import class4 from '../icons/class4.png';
import class5 from '../icons/class5.png';
import ObjectSidebar from './ObjectSidebar';
import EntitySidebar from './EntitySidebar';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import { useLanguage } from '../context/LanguageContext';
import translations from '../locales/translations.json';

const className = (classNumber, language) => {
    switch (classNumber) {
        case 0:
            return language === 'en' ? 'Safe' : 'Sûr';
        case 1:
            return language === 'en' ? 'Non-environmental' : 'Non-environnementale';
        case 2:
            return language === 'en' ? 'Environmental' : 'Environnementale';
        case 3:
            return language === 'en' ? 'Dimensional' : 'Dimensionnelle';
        default:
            return language === 'en' ? 'Safe' : 'Sûr';
    }
};

const dimensionTypeName = (dimensionType, language) => {
    switch (dimensionType) {
        case 1:
            return language === 'en' ? 'Dimension' : 'Dimension';
        case 2:
            return language === 'en' ? 'Sub-Dimension' : 'Sous-Dimension';
        case 3:
            return language === 'en' ? 'Abnormal Dimension' : 'Dimension anormale';
        case 4:
            return 'Frontrooms';
        default:
            return '';
    }
};

const Sidebar = ({ node, onClose, selectedObject, onSelectObject, selectedEntity, onSelectEntity }) => {
    const { language } = useLanguage();
    const [logo, setLogo] = useState(orbLogo);
    const [currentLabel, setCurrentLabel] = useState('');
    const [currentLargeLabel, setCurrentLargeLabel] = useState('');

    useEffect(() => {
        if (node) {
            switch (node.averageClass) {
                case 0:
                    setLogo(class0);
                    break;
                case 1:
                    setLogo(class1);
                    break;
                case 2:
                    setLogo(class2);
                    break;
                case 3:
                    setLogo(class3);
                    break;
                case 4:
                    setLogo(class4);
                    break;
                case 5:
                    setLogo(class5);
                    break;
                default:
                    setLogo(orbLogo);
                    break;
            }
            setCurrentLabel(language === 'en' ? node.labelEN : node.label);
            setCurrentLargeLabel(language === 'en' ? node.largeLabelEN : node.largeLabel);
        }
    }, [node, language]);

    useEffect(() => {
        if (!node) {
            onSelectObject(null);
            onSelectEntity(null);
        }
    }, [node, onSelectObject, onSelectEntity]);

    useEffect(() => {
        if (selectedObject) {
            onSelectEntity(null);
        }
    }, [selectedObject, onSelectEntity]);

    useEffect(() => {
        if (selectedEntity) {
            onSelectObject(null);
        }
    }, [selectedEntity, onSelectObject]);

    if (!node) return null;

    const handleButtonClick = () => {
        window.open(node.link, '_blank');
    };

    const handleObjectClick = (objet) => {
        onSelectObject(objet);
    };

    const handleEntityClick = (entity) => {
        onSelectEntity(entity);
    };

    const handleClose = () => {
        onSelectObject(null);
        onSelectEntity(null);
        onClose();
    };

    const sortedObjects = node.objets ? [...node.objets].sort((a, b) => (language === 'en' ? a.labelEN.localeCompare(b.labelEN) || a.largeLabelEN.localeCompare(b.largeLabelEN) : a.label.localeCompare(b.label) || a.largeLabel.localeCompare(b.largeLabel))) : [];
    const sortedEntities = node.entities ? [...node.entities].sort((a, b) => (language === 'en' ? a.labelEN.localeCompare(b.labelEN) || a.largeLabelEN.localeCompare(b.largeLabelEN) : a.label.localeCompare(b.label) || a.largeLabel.localeCompare(b.largeLabel))) : [];

    const chartData = [
        { name: translations.sidebar.entityRate[language], value: node.tauxEntite || 0 },
        { name: translations.sidebar.inhabitability[language], value: node.inhabitabilite || 0 },
        { name: translations.sidebar.retention[language], value: node.retention || 0 },
        { name: translations.sidebar.tensions[language], value: node.tensions || 0 },
        { name: translations.sidebar.incomprehensibility[language], value: node.incomprehensibilite || 0 }
    ];

    return (
        <>
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '300px',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.25)',
                color: 'white',
                padding: '20px',
                boxSizing: 'border-box',
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
                zIndex: 900,
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: 'white transparent'
            }}>
                <style>
                    {`
                        ::-webkit-scrollbar {
                            width: 8px;
                        }
                        ::-webkit-scrollbar-thumb {
                            background-color: white;
                            border-radius: 10px;
                        }
                        ::-webkit-scrollbar-track {
                            background: transparent;
                        }
                    `}
                </style>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <img src={logo} alt="Logo" style={{ width: '50px', height: '50px' }} />
                    <div onClick={handleClose} style={{ cursor: 'pointer', color: 'red', fontSize: '20px' }}>X</div>
                </div>
                <h2>{currentLabel === "Frontrooms" ? currentLabel : `${currentLabel} - ${currentLargeLabel}`}</h2>
                <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                    <hr />
                    <p>{translations.sidebar.typeDimension[language]}: {dimensionTypeName(node.dimensionType, language)}</p>
                    <p>{translations.sidebar.generalClass[language]}: {node.averageClass}</p>
                    <hr />
                    <p>{translations.sidebar.class[language]} {node.classNumber} : {className(node.classNumber, language)}</p>
                    <p>{translations.sidebar.entityRate[language]}: {node.tauxEntite}</p>
                    <p>{translations.sidebar.inhabitability[language]}: {node.inhabitabilite}</p>
                    <p>{translations.sidebar.retention[language]}: {node.retention}</p>
                    <p>{translations.sidebar.tensions[language]}: {node.tensions}</p>
                    <p>{translations.sidebar.incomprehensibility[language]}: {node.incomprehensibilite}</p>
                    <hr />
                    <div style={{ height: '250px', marginTop: '20px' }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <RadarChart outerRadius={50} data={chartData}>
                                <PolarGrid />
                                <PolarAngleAxis dataKey="name" tick={{ fontSize: 10, angle: 45 }} />
                                <PolarRadiusAxis angle={30} domain={[0, 5]} tickCount={6} />
                                <Radar name="Data" dataKey="value" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                            </RadarChart>
                        </ResponsiveContainer>
                    </div>
                    <hr />
                    {sortedObjects.length > 0 && (
                        <>
                            <h3>{translations.sidebar.objects[language]}</h3>
                            <ul>
                                {sortedObjects.map((objet, index) => (
                                    <li key={index} onClick={() => handleObjectClick(objet)} style={{ cursor: 'pointer', color: 'lightblue' }}>
                                        {language === 'en' ? `${objet.labelEN} - ${objet.largeLabelEN}` : `${objet.label} - ${objet.largeLabel}`}
                                    </li>
                                ))}
                            </ul>
                            <hr />
                        </>
                    )}
                    {sortedEntities.length > 0 && (
                        <>
                            <h3>{translations.sidebar.entities[language]}</h3>
                            <ul>
                                {sortedEntities.map((entity, index) => (
                                    <li key={index} onClick={() => handleEntityClick(entity)} style={{ cursor: 'pointer', color: '#ff4747' }}>
                                        {language === 'en' ? `${entity.labelEN} - ${entity.largeLabelEN}` : `${entity.label} - ${entity.largeLabel}`}
                                    </li>
                                ))}
                            </ul>
                            <hr />
                        </>
                    )}
                </div>
                <button onClick={handleButtonClick} style={{
                    marginTop: '20px',
                    padding: '10px 20px',
                    backgroundColor: 'white',
                    color: 'black',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}>
                    {translations.sidebar.openLink[language]}
                </button>
            </div>
            {selectedObject && (
                <ObjectSidebar objet={selectedObject} onClose={() => onSelectObject(null)} />
            )}
            {selectedEntity && (
                <EntitySidebar entity={selectedEntity} onClose={() => onSelectEntity(null)} />
            )}
        </>
    );
};

export default Sidebar;
