import React, { useEffect, useMemo, useState, useCallback } from 'react';
import ReactFlow, { addEdge, Background, useNodesState, useEdgesState, useReactFlow } from 'react-flow-renderer';
import Cookies from 'js-cookie';
import CookieConsent from 'react-cookie-consent';
import FloatingEdge from './FloatingEdge';
import CustomNode from './CustomNode';
import Legend from './Legend';
import Sidebar from './Sidebar';
import { data } from '../data';
import SearchBar from './SearchBar';
import EdgeDescription from './EdgeDescription';
import translations from '../locales/translations.json';
import { useLanguage } from '../context/LanguageContext';

const Map = () => {
    const { language } = useLanguage();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [selectedNodeId, setSelectedNodeId] = useState(null);
    const [selectedEdgeId, setSelectedEdgeId] = useState(null);
    const [selectedEdgeDescriptions, setSelectedEdgeDescriptions] = useState([]);
    const [nodePositions, setNodePositions] = useState({});
    const [selectedNode, setSelectedNode] = useState(null);
    const [selectedObject, setSelectedObject] = useState(null);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [cookiesAccepted, setCookiesAccepted] = useState(Cookies.get('cookiesAccepted') === 'true');
    const reactFlowInstance = useReactFlow();

    useEffect(() => {
        if (cookiesAccepted) {
            const savedNodePositions = Cookies.get('nodePositions');
            if (savedNodePositions) {
                setNodePositions(JSON.parse(savedNodePositions));
            }
        }
    }, [cookiesAccepted]);

    useEffect(() => {
        const fetchData = async () => {
            const { dimensions, connections } = await data;

            const initialNodes = dimensions.map(dimension => {
                return {
                    id: dimension.id.toString(),
                    type: 'custom',
                    label: dimension.label,
                    largeLabel: dimension.largeLabel,
                    labelEN: dimension.labelEN,
                    largeLabelEN: dimension.largeLabelEN,
                    dimensionType: dimension.dimensionType,
                    averageClass: dimension.averageClass,
                    link: dimension.link,
                    classNumber: dimension.classNumber,
                    tauxEntite: dimension.tauxEntite,
                    inhabitabilite: dimension.inhabitabilite,
                    retention: dimension.retention,
                    tensions: dimension.tensions,
                    incomprehensibilite: dimension.incomprehensibilite,
                    objets: dimension.objets ? dimension.objets.map(objet => ({
                        ...objet,
                        label: objet.label,
                        largeLabel: objet.largeLabel,
                        labelEN: objet.labelEN,
                        largeLabelEN: objet.largeLabelEN,
                        typeObjet: objet.typeObjet,
                        typeObjetEN: objet.typeObjetEN,
                        classObj: objet.classObj,
                        classObjEN: objet.classObjEN
                    })) : [],
                    entities: dimension.entities ? dimension.entities.map(entity => ({
                        ...entity,
                        label: entity.label,
                        largeLabel: entity.largeLabel,
                        labelEN: entity.labelEN,
                        largeLabelEN: entity.largeLabelEN,
                        typeIndiceGlobal: entity.typeIndiceGlobal,
                        typeIndiceGlobalEN: entity.typeIndiceGlobalEN,
                        typeIndiceDangerosite: entity.typeIndiceDangerosite,
                        typeIndiceDangerositeEN: entity.typeIndiceDangerositeEN,
                        typeIndiceCognitive: entity.typeIndiceCognitive,
                        typeIndiceCognitiveEN: entity.typeIndiceCognitiveEN,
                        typeIndiceAptitude: entity.typeIndiceAptitude,
                        typeIndiceAptitudeEN: entity.typeIndiceAptitudeEN
                    })) : [],
                    data: {
                        label: dimension.label,
                        largeLabel: dimension.largeLabel,
                        labelEN: dimension.labelEN,
                        largeLabelEN: dimension.largeLabelEN
                    },
                    position: nodePositions[dimension.id.toString()] || { x: dimension.x, y: dimension.y }
                };
            });

            const initialEdges = connections.map(connection => {
                const sourceNode = initialNodes.find(node => node.id === connection.idEntrance.toString());
                const targetNode = initialNodes.find(node => node.id === connection.idExit.toString());

                return {
                    id: `e${connection.idEntrance}-${connection.idExit}`,
                    source: connection.idEntrance.toString(),
                    target: connection.idExit.toString(),
                    description: language === 'en' ? connection.descriptionEN : connection.description,
                    type: 'floating',
                    animated: true,
                    style: { stroke: 'white', pointerEvents: 'all', cursor: 'crosshair' },
                    markerEnd: {
                        type: 'arrowclosed',
                        color: 'white'
                    },
                    data: {
                        labelEntrance: sourceNode ? sourceNode.data.label : 'Unknown',
                        labelExit: targetNode ? targetNode.data.label : 'Unknown',
                        descriptionEN: connection.descriptionEN,
                        description: connection.description,
                        onClick: (event, edge) => {
                            if (selectedEdgeId === edge.id) {
                                setSelectedEdgeId(null);
                                setSelectedEdgeDescriptions([]);
                            } else {
                                const descriptions = connections.filter(conn =>
                                    (conn.idEntrance.toString() === edge.source && conn.idExit.toString() === edge.target) ||
                                    (conn.idEntrance.toString() === edge.target && conn.idExit.toString() === edge.source)
                                ).map(conn => ({
                                    description: language === 'en' ? conn.descriptionEN : conn.description,
                                    labelEntrance: sourceNode ? sourceNode.data.label : 'Unknown',
                                    labelExit: targetNode ? targetNode.data.label : 'Unknown'
                                }));
                                setSelectedEdgeId(edge.id);
                                setSelectedEdgeDescriptions(descriptions);
                            }
                            setSelectedNodeId(null);
                            setSelectedObject(null);
                            setSelectedEntity(null);
                        }
                    }
                };
            });

            const edgePairs = new Set();
            const adjustedEdges = initialEdges.map(edge => {
                const pairKey = `${edge.source}-${edge.target}`;
                if (edgePairs.has(pairKey)) {
                    edge.data.offset = 20;
                } else {
                    edgePairs.add(pairKey);
                    edge.data.offset = 0;
                }
                return edge;
            });

            setNodes(initialNodes);
            setEdges(adjustedEdges);
        };

        fetchData();
    }, [language, setNodes, setEdges, selectedNodeId, selectedEdgeId, nodePositions]);

    useEffect(() => {
        if (selectedEdgeId) {
            const [sourceId, targetId] = selectedEdgeId.split('-').slice(1);
            const updatedDescriptions = edges.filter(conn =>
                (conn.source === sourceId && conn.target === targetId) ||
                (conn.source === targetId && conn.target === sourceId)
            ).map(conn => ({
                description: language === 'en' ? conn.data.descriptionEN : conn.data.description,
                labelEntrance: conn.data.labelEntrance,
                labelExit: conn.data.labelExit
            }));
            setSelectedEdgeDescriptions(updatedDescriptions);
        }
    }, [language, selectedEdgeId, edges]);

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);
    const edgeTypes = useMemo(() => ({ floating: FloatingEdge }), []);
    const nodeTypes = useMemo(() => ({ custom: CustomNode }), []);

    const handleNodeClick = useCallback((_, node) => {
        setSelectedNodeId(prevId => {
            if (prevId === node.id) {
                setSelectedNode(null);
                return null;
            } else {
                setSelectedNode(node);
                return node.id;
            }
        });
        setSelectedEdgeId(null);
        setSelectedEdgeDescriptions([]);
        setSelectedObject(null);
        setSelectedEntity(null);
    }, []);

    const handleNodeDragStop = useCallback((_, node) => {
        const updatedPositions = { ...nodePositions, [node.id]: node.position };
        setNodePositions(updatedPositions);
        if (cookiesAccepted) {
            Cookies.set('nodePositions', JSON.stringify(updatedPositions), { expires: 7 });
        }
        setNodes((nds) => nds.map((n) => (n.id === node.id ? { ...n, position: node.position } : n)));
    }, [setNodes, nodePositions, cookiesAccepted]);

    const handlePaneClick = useCallback(() => {
        setSelectedNodeId(null);
        setSelectedNode(null);
        setSelectedEdgeId(null);
        setSelectedEdgeDescriptions([]);
        setSelectedObject(null);
        setSelectedEntity(null);
    }, []);

    const handleEdgeClick = useCallback((event, edge) => {
        setSelectedEdgeId(prevId => {
            if (prevId === edge.id) {
                return null;
            } else {
                return edge.id;
            }
        });
    }, []);

    useEffect(() => {
        if (selectedEdgeId) {
            const selectedEdge = edges.find(edge => edge.id === selectedEdgeId);
            if (selectedEdge) {
                const descriptions = edges.filter(conn =>
                    (conn.source === selectedEdge.source && conn.target === selectedEdge.target) ||
                    (conn.source === selectedEdge.target && conn.target === selectedEdge.source)
                ).map(conn => ({
                    description: language === 'en' ? conn.data.descriptionEN : conn.data.description,
                    labelEntrance: conn.data.labelEntrance,
                    labelExit: conn.data.labelExit
                }));
                setSelectedEdgeDescriptions(descriptions);
            }
        } else {
            setSelectedEdgeDescriptions([]);
        }
    }, [selectedEdgeId, edges, language]);

    const getConnectedNodesAndEdges = useCallback((nodeId) => {
        const connectedNodeIds = new Set();
        const connectedEdgeIds = new Set();

        edges.forEach(edge => {
            if (edge.source === nodeId || edge.target === nodeId) {
                connectedEdgeIds.add(edge.id);
                connectedNodeIds.add(edge.source);
                connectedNodeIds.add(edge.target);
            }
        });

        return { connectedNodeIds, connectedEdgeIds };
    }, [edges]);

    const getNodesConnectedByEdge = useCallback((edgeId) => {
        const edge = edges.find(edge => edge.id === edgeId);
        if (!edge) return { sourceNodeId: null, targetNodeId: null };
        return { sourceNodeId: edge.source, targetNodeId: edge.target };
    }, [edges]);

    const { connectedNodeIds, connectedEdgeIds } = useMemo(() => getConnectedNodesAndEdges(selectedNodeId), [selectedNodeId, getConnectedNodesAndEdges]);
    const { sourceNodeId, targetNodeId } = useMemo(() => getNodesConnectedByEdge(selectedEdgeId), [selectedEdgeId, getNodesConnectedByEdge]);

    const getNodeStyle = (dimensionType, isSelected, isConnected) => {
        const baseStyleOne = { borderRadius: '10px', border: '1px solid white', backgroundColor: 'rgba(255, 255, 255, 0.1)', color: isSelected ? 'black' : 'white' };
        const baseStyleTwo = { borderRadius: '10px', border: '1px solid rgb(64, 48, 186)', backgroundColor: 'rgba(0, 0, 255, 0.1)', color: isSelected ? 'black' : 'white' };
        const baseStyleThree = { borderRadius: '10px', border: '1px solid rgb(186, 48, 48)', backgroundColor: 'rgba(255, 0, 0, 0.1)', color: isSelected ? 'black' : 'white' };
        const baseStyleFour = { borderRadius: '10px', border: '1px solid rgb(44, 122, 65)', backgroundColor: 'rgba(44, 122, 65, 0.1)', color: isSelected ? 'black' : 'white' };

        switch (dimensionType) {
            case 1:
                return isSelected ? { ...baseStyleOne, backgroundColor: 'rgba(240, 240, 240, 0.8)' } : isConnected ? { ...baseStyleOne, backgroundColor: 'rgba(255, 255, 255, 0.2)' } : { ...baseStyleOne };
            case 2:
                return isSelected ? { ...baseStyleTwo, backgroundColor: 'rgba(64, 48, 186, 0.8)' } : isConnected ? { ...baseStyleTwo, backgroundColor: 'rgba(0, 0, 255, 0.2)' } : { ...baseStyleTwo };
            case 3:
                return isSelected ? { ...baseStyleThree, backgroundColor: 'rgba(186, 48, 48, 0.8)' } : isConnected ? { ...baseStyleThree, backgroundColor: 'rgba(255, 0, 0, 0.2)' } : { ...baseStyleThree };
            case 4:
                return isSelected ? { ...baseStyleFour, backgroundColor: 'rgba(44, 122, 65, 0.8)' } : isConnected ? { ...baseStyleFour, backgroundColor: 'rgba(71, 168, 97, 0.2)' } : { ...baseStyleFour };
            default:
                return baseStyleOne;
        }
    };

    const updatedNodes = useMemo(() => nodes.map(node => {
        const dimensionType = node.dimensionType;
        const averageClass = node.averageClass;
        const isSelected = selectedNodeId === node.id || sourceNodeId === node.id || targetNodeId === node.id;
        const isConnected = connectedNodeIds.has(node.id);

        const label = language === 'en' ? node.data.labelEN : node.data.label;
        const largeLabel = language === 'en' ? node.data.largeLabelEN : node.data.largeLabel;

        return {
            ...node,
            style: getNodeStyle(dimensionType, isSelected, isConnected),
            data: {
                ...node.data,
                averageClass,
                label: (
                    <div style={{
                        textAlign: 'center',
                        color: isSelected ? 'black' : 'white',
                        fontWeight: 'bold',
                        borderRadius: '10px'
                    }}>
                        {label === "Frontrooms" ? label : (
                            <>
                                {label}
                                <hr />
                                {largeLabel}
                            </>
                        )}
                    </div>
                )
            }
        };
    }), [nodes, selectedNodeId, sourceNodeId, targetNodeId, connectedNodeIds, language]);

    const updatedEdges = useMemo(() => edges.map(edge => ({
        ...edge,
        style: { stroke: 'white', pointerEvents: 'all', cursor: 'crosshair' },
        animated: selectedNodeId ? connectedEdgeIds.has(edge.id) : (selectedEdgeId ? edge.id === selectedEdgeId : true),
        markerEnd: {
            type: 'arrowclosed',
            color: 'white',
            width: 25,
            height: 25
        },
        data: {
            ...edge.data,
            descriptions: edges.filter(conn =>
                (conn.source === edge.source && conn.target === edge.target) ||
                (conn.source === edge.target && conn.target === edge.source)
            ).map(conn => ({
                description: language === 'en' ? conn.data.descriptionEN : conn.data.description,
                labelEntrance: conn.data.labelEntrance,
                labelExit: conn.data.labelExit
            })),
            onClick: (event, edge) => {
                const descriptions = edges.filter(conn =>
                    (conn.source === edge.source && conn.target === edge.target) ||
                    (conn.source === edge.target && conn.target === edge.source)
                ).map(conn => ({
                    description: language === 'en' ? conn.data.descriptionEN : conn.data.description,
                    labelEntrance: conn.data.labelEntrance,
                    labelExit: conn.data.labelExit
                }));
                setSelectedEdgeId(edge.id);
                setSelectedEdgeDescriptions(descriptions);
                setSelectedNodeId(null);
                setSelectedObject(null);
                setSelectedEntity(null);
            }
        }
    })), [edges, selectedNodeId, connectedEdgeIds, selectedEdgeId, language]);

    const handleSearch = (nodeId) => {
        const node = nodes.find(node => node.id === nodeId);
        if (node) {
            setSelectedNodeId(node.id);
            setSelectedNode(node);
            setSelectedEdgeId(null);
            setSelectedEdgeDescriptions([]);
            setSelectedObject(null);
            setSelectedEntity(null);
            const { x, y } = node.position;
            const zoom = 1.5;
            reactFlowInstance.setCenter(x, y, { zoom });
        }
    };

    return (
        <div className="map-container" style={{ position: 'relative' }}>
            <Legend />
            <SearchBar dimensions={nodes} onSearch={handleSearch} />
            <ReactFlow
                nodes={updatedNodes}
                edges={updatedEdges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onNodeClick={handleNodeClick}
                onEdgeClick={handleEdgeClick}
                onNodeDragStop={handleNodeDragStop}
                onPaneClick={handlePaneClick}
                fitView
                attributionPosition="bottom-right"
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
            >
                <Background />
            </ReactFlow>
            {selectedEdgeDescriptions.length > 0 && (
                <EdgeDescription descriptions={selectedEdgeDescriptions} />
            )}
            <Sidebar
                node={selectedNode}
                selectedObject={selectedObject}
                selectedEntity={selectedEntity}
                onSelectObject={setSelectedObject}
                onSelectEntity={setSelectedEntity}
                onClose={() => {
                    setSelectedNodeId(null);
                    setSelectedNode(null);
                    setSelectedEdgeId(null);
                    setSelectedEdgeDescriptions([]);
                    setSelectedObject(null);
                    setSelectedEntity(null);
                }}
            />
            <CookieConsent
                onAccept={() => {
                    setCookiesAccepted(true);
                    Cookies.set('cookiesAccepted', 'true', { expires: 365 });
                }}
            >
                {translations.cookie.find(c => c[language])[language]}
            </CookieConsent>
        </div>
    );
};

export default Map;
