import React from 'react';
import Select from 'react-select';
import englishFlag from '../icons/english flag.svg';
import frenchFlag from '../icons/french flag.svg';

const options = [
    { value: 'en', label: 'English', flag: englishFlag },
    { value: 'fr', label: 'Français', flag: frenchFlag }
];

const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: 'white',
        borderColor: 'white',
        color: 'black',
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
        backgroundColor: state.isSelected ? 'darkgray' : state.isFocused ? 'lightgray' : 'darkgray',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'black',
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: 'darkgray',
        border: '1px solid white',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'black',
    }),
    input: (provided) => ({
        ...provided,
        color: 'black',
    }),
};

const formatOptionLabel = ({ label, flag }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={flag} alt="" style={{ width: 20, height: 20, marginRight: 10, borderRadius: '50%' }} />
        {label}
    </div>
);

const LanguageSelector = ({ language, onChange }) => {
    return (
        <Select
            value={options.find(option => option.value === language)}
            onChange={(selectedOption) => onChange(selectedOption.value)}
            options={options}
            styles={customStyles}
            formatOptionLabel={formatOptionLabel}
            isSearchable={false}
        />
    );
};

export default LanguageSelector;