import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import translations from '../locales/translations.json';
import { useLanguage } from '../context/LanguageContext';
import SidebarMenu from './SidebarMenu';

export default function Header() {
    const { language } = useLanguage();
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const sidebarRef = useRef(null);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setSidebarOpen(false);
        }
    };

    useEffect(() => {
        if (isSidebarOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSidebarOpen]);

    return (
        <>
            <header className="header">
                <div className="hamburger-menu" onClick={toggleSidebar}>
                    &#9776;
                </div>
                <div className="title">
                    <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <h1>{translations.header.title[language]}</h1>
                    </Link>
                </div>
            </header>
            <div ref={sidebarRef}>
                <SidebarMenu isOpen={isSidebarOpen} onClose={toggleSidebar} />
            </div>
        </>
    );
}
