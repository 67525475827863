import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { data } from '../data';
import Legend from './Legend';
import { useLanguage } from '../context/LanguageContext';
import translations from '../locales/translations.json';

import orbLogo from '../icons/orb_logo_white.png';
import class0 from '../icons/class0.png';
import class1 from '../icons/class1.png';
import class2 from '../icons/class2.png';
import class3 from '../icons/class3.png';
import class4 from '../icons/class4.png';
import class5 from '../icons/class5.png';

const PathFinder = () => {
    const { language } = useLanguage();
    const [dimensions, setDimensions] = useState([]);
    const [connections, setConnections] = useState([]);
    const [startDimension, setStartDimension] = useState(null);
    const [endDimension, setEndDimension] = useState(null);
    const [path, setPath] = useState([]);
    const [allPaths, setAllPaths] = useState([]);
    const [noPathMessage, setNoPathMessage] = useState('');
    const [filter, setFilter] = useState({ value: 'fastest', label: translations.pathfinder.filterOptions.fastest[language] });
    const [showDetails, setShowDetails] = useState(false);
    const [filterName, setFilterName] = useState(filter.label);

    useEffect(() => {
        const fetchData = async () => {
            const { dimensions, connections } = await data;
            setDimensions(dimensions);
            setConnections(connections);
        };

        fetchData();
    }, []);

    useEffect(() => {
        setFilter(f => ({ ...f, label: translations.pathfinder.filterOptions[f.value][language] }));
    }, [language]);

    const defineLogo = (averageClass) => {
        switch (averageClass) {
            case 0:
                return class0;
            case 1:
                return class1;
            case 2:
                return class2;
            case 3:
                return class3;
            case 4:
                return class4;
            case 5:
                return class5;
            default:
                return orbLogo;
        }
    };

    const options = useMemo(() => dimensions.map(d => ({
        value: d.id,
        label: `${language === 'en' ? d.labelEN : d.label} - ${language === 'en' ? d.largeLabelEN : d.largeLabel}`
    })), [dimensions, language]);

    const filterOptions = [
        { value: 'fastest', label: translations.pathfinder.filterOptions.fastest[language] },
        { value: 'safest', label: translations.pathfinder.filterOptions.safest[language] },
        { value: 'dangerous', label: translations.pathfinder.filterOptions.dangerous[language] }
    ];

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            borderColor: 'white',
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'white' : 'black',
            backgroundColor: state.isSelected ? 'darkgray' : state.isFocused ? 'lightgray' : 'darkgray',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'darkgray',
            border: '1px solid white',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'black',
        }),
        input: (provided) => ({
            ...provided,
            color: 'black',
        }),
    };

    const findAllPaths = (startId, endId, visited = new Set()) => {
        const paths = [];
        const queue = [[startId, [startId]]];

        while (queue.length > 0) {
            const [node, path] = queue.shift();
            if (node === endId) {
                paths.push(path);
                continue;
            }

            if (!visited.has(node)) {
                visited.add(node);
                const neighbors = connections
                    .filter(c => c.idEntrance === node)
                    .map(c => c.idExit);

                for (const neighbor of neighbors) {
                    if (!visited.has(neighbor)) {
                        queue.push([neighbor, [...path, neighbor]]);
                    }
                }
            }
        }
        return paths;
    };

    const getAverageClass = (path) => {
        const classes = path.map(id => dimensions.find(d => d.id === id).averageClass);
        return classes.reduce((sum, cls) => sum + cls, 0) / classes.length;
    };

    const findPath = (startId, endId) => {
        const allPaths = findAllPaths(startId, endId);
        setAllPaths(allPaths);

        if (allPaths.length === 0) return null;

        if (filter.value === 'fastest') {
            const shortestPaths = allPaths.filter(path => path.length === Math.min(...allPaths.map(p => p.length)));
            if (shortestPaths.length === 1) {
                return shortestPaths[0];
            } else {
                return shortestPaths.reduce((safestPath, currentPath) =>
                    getAverageClass(currentPath) < getAverageClass(safestPath) ? currentPath : safestPath, shortestPaths[0]);
            }
        }

        if (filter.value === 'safest') {
            return allPaths.reduce((safestPath, currentPath) =>
                getAverageClass(currentPath) < getAverageClass(safestPath) ? currentPath : safestPath, allPaths[0]);
        }

        if (filter.value === 'dangerous') {
            return allPaths.reduce((mostDangerousPath, currentPath) =>
                getAverageClass(currentPath) > getAverageClass(mostDangerousPath) ? currentPath : mostDangerousPath, allPaths[0]);
        }

        return allPaths[0];
    };

    const handleFindPath = () => {
        setNoPathMessage('');
        setShowDetails(false);
        setFilterName(filter.label)
        if (startDimension && endDimension) {
            const foundPath = findPath(startDimension.value, endDimension.value);
            if (foundPath) {
                setPath(foundPath);
            } else {
                setPath([]);
                setNoPathMessage(translations.pathfinder.noPathMessage[language]);
            }
        }
    };

    const getNodeStyle = (dimensionType) => {
        switch (dimensionType) {
            case 1:
                return 'node-type-1';
            case 2:
                return 'node-type-2';
            case 3:
                return 'node-type-3';
            case 4:
                return 'node-type-4';
            default:
                return 'node-type-default';
        }
    };

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    return (
        <div className="pathfinder-container">
            <h2 className="pathfinder-header">{translations.pathfinder.header[language]}</h2>
            <Legend />
            <div className="pathfinder-select-container">
                <div className="pathfinder-select">
                    <Select
                        options={options}
                        value={startDimension}
                        onChange={setStartDimension}
                        placeholder={translations.pathfinder.selectStart[language]}
                        styles={customStyles}
                        classNamePrefix="react-select"
                    />
                </div>
                <div className="pathfinder-select">
                    <Select
                        options={options}
                        value={endDimension}
                        onChange={setEndDimension}
                        placeholder={translations.pathfinder.selectEnd[language]}
                        styles={customStyles}
                        classNamePrefix="react-select"
                    />
                </div>
            </div>
            <div className="pathfinder-select-container">
                <Select
                    options={filterOptions}
                    value={filter}
                    onChange={setFilter}
                    placeholder={translations.pathfinder.selectEnd[language]}
                    styles={customStyles}
                    classNamePrefix="react-select"
                />
            </div>
            <div className="pathfinder-button-container">
                <button onClick={handleFindPath} className="pathfinder-button">
                    {translations.pathfinder.findPathButton[language]}
                </button>
            </div>
            {noPathMessage && (
                <div className="pathfinder-no-path">
                    {noPathMessage}
                </div>
            )}
            {path.length > 0 && (
                <div className="pathfinder-path-container">
                    <h3>{filterName} :</h3>
                    <div className="pathfinder-path">
                        {path.map(id => {
                            const dimension = dimensions.find(d => d.id === id);
                            const nodeStyle = getNodeStyle(dimension.dimensionType);
                            return (
                                <div key={id} className={`pathfinder-node ${nodeStyle}`}>
                                    <div className="pathfinder-node-label">{language === 'en' ? dimension.labelEN : dimension.label}</div>
                                    {dimension.label !== 'Frontrooms' && (
                                        <>
                                            <div className="pathfinder-node-separator"></div>
                                            <div className="pathfinder-node-largeLabel">{language === 'en' ? dimension.largeLabelEN : dimension.largeLabel}</div>
                                        </>
                                    )}
                                    <img
                                        src={defineLogo(dimension.averageClass)}
                                        alt="Logo de la classe de la dimension"
                                        className="pathfinder-node-logo"
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <button onClick={toggleDetails} className="pathfinder-details-button">
                        {showDetails ? translations.pathfinder.hideDetails[language] : translations.pathfinder.showDetails[language]}
                    </button>
                    {showDetails && (
                        <div className="pathfinder-details-container">
                            {allPaths.map((path, index) => (
                                <div key={index} className="pathfinder-details-item">
                                    <h4>{translations.pathfinder.path[language]} {index + 1}</h4>
                                    <hr></hr>
                                    <br></br>
                                    <div className='pathfinder-detail-container'>
                                        {path.map(id => {
                                            const dimension = dimensions.find(d => d.id === id);
                                            const nodeStyle = getNodeStyle(dimension.dimensionType);
                                            return (
                                                <div key={id} className={`pathfinder-node ${nodeStyle}`}>
                                                    <div className="pathfinder-node-label">{language === 'en' ? dimension.labelEN : dimension.label}</div>
                                                    {dimension.label !== 'Frontrooms' && (
                                                        <>
                                                            <div className="pathfinder-node-separator"></div>
                                                            <div className="pathfinder-node-largeLabel">{language === 'en' ? dimension.largeLabelEN : dimension.largeLabel}</div>
                                                        </>
                                                    )}
                                                    <img
                                                        src={defineLogo(dimension.averageClass)}
                                                        alt="Logo de la classe de la dimension"
                                                        className="pathfinder-node-logo"
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div>{translations.pathfinder.averageClass[language]}: {getAverageClass(path).toFixed(2)}</div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default PathFinder;
