import React from 'react';

const EdgeDescription = ({ descriptions }) => {
    if (!descriptions.length) return null;

    return (
        <div style={{
            position: 'absolute',
            bottom: '10px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            padding: '10px',
            borderRadius: '5px',
            zIndex: 1000,
            maxWidth: '90%'
        }}>
            {descriptions.map((desc, index) => (
                <div key={index}>
                    {desc.labelEntrance} → {desc.labelExit} : {desc.description}
                    {index < descriptions.length - 1 && <hr />}
                </div>
            ))}
        </div>
    );
};

export default EdgeDescription;