import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ReactFlowProvider } from 'react-flow-renderer';
import './App.css';

import Header from './pages/Header';
import Map from './pages/Map';
import PathFinder from './pages/PathFinder';
import { LanguageProvider } from './context/LanguageContext';

export default function App() {
    return (
        <LanguageProvider>
            <ReactFlowProvider>
                <Router>
                    <div className="app">
                        <Header />
                        <main className="main">
                            <Routes>
                                <Route path="/" element={<Map />} />
                                <Route path="/pathFinder" element={<PathFinder />} />
                            </Routes>
                        </main>
                    </div>
                </Router>
            </ReactFlowProvider>
        </LanguageProvider>
    )
};