import React from 'react';
import { useLanguage } from '../context/LanguageContext';
import translations from '../locales/translations.json';

const ObjectSidebar = ({ objet, onClose }) => {
    const { language } = useLanguage();

    if (!objet) return null;

    const handleButtonClick = () => {
        window.open(objet.link, '_blank');
    };

    const getLabel = (item) => (language === 'en' ? item.labelEN : item.label);
    const getLargeLabel = (item) => (language === 'en' ? item.largeLabelEN : item.largeLabel);
    const getTypeObjet = (item) => (language === 'en' ? item.typeObjetEN : item.typeObjet);
    const getClassObj = (item) => (language === 'en' ? item.classObjEN : item.classObj);

    return (
        <div style={{
            position: 'absolute',
            top: '10px',
            left: '310px',
            width: '250px',
            height: 'calc(100% - 20px)',
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            color: 'white',
            padding: '10px',
            boxSizing: 'border-box',
            borderRadius: '10px',
            zIndex: '901',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto'
        }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>{getLabel(objet)}</div>
                <div onClick={onClose} style={{ cursor: 'pointer', color: 'red', fontSize: '20px' }}>X</div>
            </div>
            <h2>{getLargeLabel(objet)}</h2>
            <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                <hr />
                <p>{translations.objectSidebar.objectType[language]} : {getTypeObjet(objet)}</p>
                <p>{translations.objectSidebar.objectClassification[language]} : {getClassObj(objet)}</p>
            </div>
            <button onClick={handleButtonClick} style={{
                marginTop: '20px',
                padding: '10px 20px',
                backgroundColor: 'white',
                color: 'black',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
            }}>
                {translations.sidebar.openLink[language]}
            </button>
        </div>
    );
};

export default ObjectSidebar;
