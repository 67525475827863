import React, { useMemo } from 'react';
import Select from 'react-select';
import { useLanguage } from '../context/LanguageContext';
import translations from '../locales/translations.json';

const SearchBar = ({ dimensions, onSearch }) => {
    const { language } = useLanguage();

    const options = useMemo(() => dimensions.map(d => ({
        value: d.id,
        label: language === 'en' ? `${d.labelEN} - ${d.largeLabelEN}` : `${d.label} - ${d.largeLabel}`
    })), [dimensions, language]);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            borderColor: 'white',
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'white' : 'black',
            backgroundColor: state.isSelected ? 'darkgray' : state.isFocused ? 'lightgray' : 'darkgray',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'darkgray',
            border: '1px solid white',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'black',
        }),
        input: (provided) => ({
            ...provided,
            color: 'black',
        }),
    };

    const handleChange = (selectedOption) => {
        onSearch(selectedOption.value);
    };

    return (
        <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1000, width: '300px' }}>
            <Select
                options={options}
                onChange={handleChange}
                placeholder={translations.searchBar.placeholder[language]}
                styles={customStyles}
            />
        </div>
    );
};

export default SearchBar;
