import React from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import { useLanguage } from '../context/LanguageContext';
import translations from '../locales/translations.json';

const EntitySidebar = ({ entity, onClose }) => {
    const { language } = useLanguage();

    if (!entity) return null;

    const handleButtonClick = () => {
        window.open(entity.link, '_blank');
    };

    const chartData = [
        { name: translations.entitySidebar.globalIndex[language], value: entity.indiceGlobale || 0 },
        { name: translations.entitySidebar.dangerIndex[language], value: entity.indiceDangerosite || 0 },
        { name: translations.entitySidebar.cognitiveIndex[language], value: entity.indiceCognitive || 0 },
        { name: translations.entitySidebar.aptitudeIndex[language], value: entity.indiceAptitude || 0 },
        { name: translations.entitySidebar.agility[language], value: entity.agilite || 0 },
        { name: translations.entitySidebar.intelligence[language], value: entity.intelligence || 0 },
        { name: translations.entitySidebar.hostility[language], value: entity.hostilite || 0 },
        { name: translations.entitySidebar.aggressiveness[language], value: entity.agressivite || 0 },
        { name: translations.entitySidebar.stealth[language], value: entity.furtivite || 0 },
        { name: translations.entitySidebar.resilience[language], value: entity.resilience || 0 },
        { name: translations.entitySidebar.endurance[language], value: entity.endurance || 0 },
        { name: translations.entitySidebar.perception[language], value: entity.perception || 0 },
        { name: translations.entitySidebar.communication[language], value: entity.communication || 0 },
        { name: translations.entitySidebar.sociability[language], value: entity.sociabilite || 0 },
        { name: translations.entitySidebar.morality[language], value: entity.moralite || 0 },
    ];

    const getColorByIndiceGlobal = (indice) => {
        if (indice >= 0 && indice <= 2) return 'green';
        if (indice >= 3 && indice <= 4) return '#009900';
        if (indice >= 5 && indice <= 6) return '#ff9900';
        if (indice >= 7 && indice <= 8) return '#ff6600';
        if (indice >= 9 && indice <= 10) return 'red';
        return 'white';
    };

    const getLabel = (item) => (language === 'en' ? item.labelEN : item.label);
    const getLargeLabel = (item) => (language === 'en' ? item.largeLabelEN : item.largeLabel);
    const getTypeIndiceDangerosite = () => (language === 'en' ? entity.typeIndiceDangerositeEN : entity.typeIndiceDangerosite);
    const getTypeIndiceCognitive = () => (language === 'en' ? entity.typeIndiceCognitiveEN : entity.typeIndiceCognitive);
    const getTypeIndiceAptitude = () => (language === 'en' ? entity.typeIndiceAptitudeEN : entity.typeIndiceAptitude);

    return (
        <div style={{
            position: 'absolute',
            top: '10px',
            left: '310px',
            width: '400px',
            height: 'calc(100% - 20px)',
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            color: 'white',
            padding: '10px',
            boxSizing: 'border-box',
            borderRadius: '10px',
            zIndex: 901,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto'
        }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>{getLabel(entity)}</div>
                <div onClick={onClose} style={{ cursor: 'pointer', color: 'red', fontSize: '20px' }}>X</div>
            </div>
            <h2>{getLargeLabel(entity)}</h2>
            <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                <hr />
                <p style={{ color: getColorByIndiceGlobal(entity.indiceGlobale) }}>
                    {translations.entitySidebar.globalIndex[language]} : {entity.indiceGlobale}
                </p>
                <p style={{ color: getColorByIndiceGlobal(entity.indiceGlobale) }}>
                    {translations.entitySidebar.globalIndexType[language]} : {entity.typeIndiceGlobal}
                </p>
                <hr />
                <p>{translations.entitySidebar.dangerIndex[language]} : {entity.indiceDangerosite} ({getTypeIndiceDangerosite()})</p>
                <p>{translations.entitySidebar.cognitiveIndex[language]} : {entity.indiceCognitive} ({getTypeIndiceCognitive()})</p>
                <p>{translations.entitySidebar.aptitudeIndex[language]} : {entity.indiceAptitude} ({getTypeIndiceAptitude()})</p>
                <hr />
                <p>{translations.entitySidebar.agility[language]} : {entity.agilite}</p>
                <p>{translations.entitySidebar.intelligence[language]} : {entity.intelligence}</p>
                <p>{translations.entitySidebar.hostility[language]} : {entity.hostilite}</p>
                <p>{translations.entitySidebar.aggressiveness[language]} : {entity.agressivite}</p>
                <p>{translations.entitySidebar.stealth[language]} : {entity.furtivite}</p>
                <p>{translations.entitySidebar.resilience[language]} : {entity.resilience}</p>
                <p>{translations.entitySidebar.endurance[language]} : {entity.endurance}</p>
                <p>{translations.entitySidebar.perception[language]} : {entity.perception}</p>
                <p>{translations.entitySidebar.communication[language]} : {entity.communication}</p>
                <p>{translations.entitySidebar.sociability[language]} : {entity.sociabilite}</p>
                <p>{translations.entitySidebar.morality[language]} : {entity.moralite}</p>
                <hr />
                <div style={{ height: '300px', marginTop: '20px' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <RadarChart outerRadius={120} data={chartData}>
                            <PolarGrid />
                            <PolarAngleAxis dataKey="name" tick={{ fontSize: 10, angle: 45 }} />
                            <PolarRadiusAxis angle={30} domain={[0, 10]} tickCount={11} />
                            <Radar name="Data" dataKey="value" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                        </RadarChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <button onClick={handleButtonClick} style={{
                marginTop: '20px',
                padding: '10px 20px',
                backgroundColor: 'white',
                color: 'black',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
            }}>
                {translations.sidebar.openLink[language]}
            </button>
        </div>
    );
};

export default EntitySidebar;
