import React from 'react';
import { Link } from 'react-router-dom';
import translations from '../locales/translations.json';
import LanguageSelector from './LanguageSelector';
import { useLanguage } from '../context/LanguageContext';

export default function SidebarMenu({ isOpen, onClose }) {
    const { language, changeLanguage } = useLanguage();

    return (
        <div className={`sidebar-menu ${isOpen ? 'open' : ''}`}>
            <div className="sidebar-header">
                <button className="close-button" onClick={onClose}>✖</button>
            </div>
            <div className="sidebar-content">
                <LanguageSelector language={language} onChange={changeLanguage} />
                <nav className="nav-links">
                    <a href="http://backrooms-france.wikidot.com" target="_blank" rel="noopener noreferrer">{translations.header.originalSite[language]}</a>
                    <a href="http://backrooms-france-sandbox.wikidot.com" target="_blank" rel="noopener noreferrer">{translations.header.sandboxSite[language]}</a>
                    <Link to="/pathFinder" className="link" onClick={onClose}>{translations.header.backroomsRoute[language]}</Link>
                </nav>
            </div>
        </div>
    );
}
