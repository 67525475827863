import { useReactFlow, getBezierPath } from 'react-flow-renderer';
import { getEdgeParams } from './utils';

function FloatingEdge({ id, source, target, markerEnd, style, data }) {
  const { getNode } = useReactFlow();
  const sourceNode = getNode(source);
  const targetNode = getNode(target);

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  const offset = data.offset || 0;
  const edgePath = getBezierPath({
    sourceX: sx + offset,
    sourceY: sy + offset,
    sourcePosition: sourcePos,
    targetX: tx + offset,
    targetY: ty + offset,
    targetPosition: targetPos
  });

  const handleEdgeClick = (event) => {
    event.stopPropagation();
    if (data && data.onClick) {
      data.onClick(event, { id, source, target });
    }
  };

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        style={{ ...style, pointerEvents: 'all', cursor: 'crosshair' }}
        onClick={handleEdgeClick}
      />
      {data.selected && data.descriptions.length > 0 && (
        <>
          {data.descriptions.map((desc, index) => (
            <text key={index}>
              <tspan x={(sx + tx) / 2} y={(sy + ty) / 2 + (index * 20)} fill="white" textAnchor="middle" dy={-10}>
                {`${data.labelEntrance} → ${data.labelExit}: ${desc}`}
              </tspan>
            </text>
          ))}
        </>
      )}
    </>
  );
}

export default FloatingEdge;