const BASE_URL = 'https://api.backrooms-france.map.alexsender.fr/api/';
const ENDPOINTS = [
    'dimensions', 'connections', 'dimensionObjets', 'dimensionsEntities',
    'typeObjets', 'classificationObjet', 'objets', 'entities', 'typeIndiceAptitude', 'typeIndiceCognitive',
    'typeIndiceDangerosite', 'typeIndiceGlobal'
];

const getRandomInt = max => Math.floor(Math.random() * max);

const fetchData = async url => {
    const response = await fetch(url);
    if (!response.ok) throw new Error(`Failed to fetch ${url}`);
    return response.json();
};

const fetchEndpoints = () => Promise.all(
    ENDPOINTS.map(endpoint => fetchData(`${BASE_URL}${endpoint}`))
);

const generateNonOverlappingDimensions = (
    dimensions, width, height, spacingFactor, dimensionsObjets, dimensionsEntities, objets,
    entites, typeObjet, classificationObjet, typeIndiceAptitude, typeIndiceCognitive,
    typeIndiceDangerosite, typeIndiceGlobal
) => {
    const positions = [];
    const count = dimensions.length;
    const stepX = (width / Math.sqrt(count)) * spacingFactor;
    const stepY = (height / Math.sqrt(count)) * spacingFactor;

    const objetsMap = new Map(objets.map(o => [o.id, o]));
    const typeObjetMap = new Map(typeObjet.map(t => [t.id, { label: t.label, labelEN: t.labelEN }]));
    const classificationObjetMap = new Map(classificationObjet.map(c => [c.id, { label: c.label, labelEN: c.labelEN }]));
    const typeIndiceAptitudeMap = new Map(typeIndiceAptitude.map(t => [t.id, { label: t.label, labelEN: t.labelEN }]));
    const typeIndiceCognitiveMap = new Map(typeIndiceCognitive.map(t => [t.id, { label: t.label, labelEN: t.labelEN }]));
    const typeIndiceDangerositeMap = new Map(typeIndiceDangerosite.map(t => [t.id, { label: t.label, labelEN: t.labelEN }]));
    const typeIndiceGlobalMap = new Map(typeIndiceGlobal.map(t => [t.id, t.label]));

    for (let i = 0; i < count; i++) {
        const dimension = dimensions[i];
        const x = (i % Math.sqrt(count)) * stepX + getRandomInt(stepX / 2);
        const y = Math.floor(i / Math.sqrt(count)) * stepY + getRandomInt(stepY / 2);

        const dimensionObjetsFiltered = dimensionsObjets.filter(dimObj => dimObj.idDimension === dimension.id);
        const dimensionsEntitiesFiltered = dimensionsEntities.filter(dimEntities => dimEntities.idDimension === dimension.id);

        const objetsWithNames = dimensionObjetsFiltered.map(dimObj => {
            const objet = objetsMap.get(dimObj.idObjets);
            return {
                ...dimObj,
                label: objet?.label || 'Unknown',
                largeLabel: objet?.largeLabel || 'Unknown LargeLabel',
                labelEN: objet?.labelEN || 'Unknown LabelEN',
                largeLabelEN: objet?.largeLabelEN || 'Unknown LargeLabelEN',
                link: objet?.link || 'Unknown Link',
                typeObjet: typeObjetMap.get(objet?.typeObjet)?.label || 'Unknown',
                typeObjetEN: typeObjetMap.get(objet?.typeObjet)?.labelEN || 'Unknown',
                classObj: classificationObjetMap.get(objet?.classification)?.label || 'Unknown',
                classObjEN: classificationObjetMap.get(objet?.classification)?.labelEN || 'Unknown',
            };
        });

        const entitiesWithNames = dimensionsEntitiesFiltered.map(dimEntity => {
            const entity = entites.find(ent => ent.id === dimEntity.idEntites);
            return {
                ...dimEntity,
                label: entity?.label || 'Unknown',
                largeLabel: entity?.largeLabel || 'Unknown LargeLabel',
                labelEN: entity?.labelEN || 'Unknown LabelEN',
                largeLabelEN: entity?.largeLabelEN || 'Unknown LargeLabelEN',
                link: entity?.link || 'Unknown Link',
                typeIndiceAptitude: typeIndiceAptitudeMap.get(entity?.typeIndiceAptitude)?.label || 'Unknown',
                typeIndiceAptitudeEN: typeIndiceAptitudeMap.get(entity?.typeIndiceAptitude)?.labelEN || 'Unknown',
                typeIndiceCognitive: typeIndiceCognitiveMap.get(entity?.typeIndiceCognitivite)?.label || 'Unknown',
                typeIndiceCognitiveEN: typeIndiceCognitiveMap.get(entity?.typeIndiceCognitivite)?.labelEN || 'Unknown',
                typeIndiceDangerosite: typeIndiceDangerositeMap.get(entity?.typeIndiceDangerosite)?.label || 'Unknown',
                typeIndiceDangerositeEN: typeIndiceDangerositeMap.get(entity?.typeIndiceDangerosite)?.labelEN || 'Unknown',
                typeIndiceGlobal: typeIndiceGlobalMap.get(entity?.typeIndiceGlobal) || 'Unknown',
                indiceGlobale: entity?.indiceGlobale || 0,
                indiceDangerosite: entity?.indiceDangerosite || 0,
                indiceCognitive: entity?.indiceCognitivite || 0,
                indiceAptitude: entity?.indiceAptitude || 0,
                agilite: entity?.agilite || 0,
                intelligence: entity?.intelligence || 0,
                hostilite: entity?.hostilite || 0,
                agressivite: entity?.agressivite || 0,
                furtivite: entity?.furtivite || 0,
                resilience: entity?.resilience || 0,
                endurance: entity?.endurance || 0,
                perception: entity?.perception || 0,
                communication: entity?.communication || 0,
                sociabilite: entity?.sociabilite || 0,
                moralite: entity?.moralite || 0
            };
        });

        positions.push({
            ...dimension,
            label: dimension.label || 'Unknown Label',
            largeLabel: dimension.largeLabel || 'Unknown LargeLabel',
            labelEN: dimension.labelEN || 'Unknown LabelEN',
            largeLabelEN: dimension.largeLabelEN || 'Unknown LargeLabelEN',
            objets: objetsWithNames,
            entities: entitiesWithNames,
            x,
            y
        });
    }
    return positions;
};

const initializeDimensionsAndConnections = async () => {
    const [
        dimensions, connections, dimensionsObjets, dimensionsEntities,
        typeObjet, classificationObjet, objets, entites,
        typeIndiceAptitude, typeIndiceCognitive, typeIndiceDangerosite, typeIndiceGlobal
    ] = await fetchEndpoints();

    const positions = generateNonOverlappingDimensions(
        dimensions, 2000, 2000, 0.5,
        dimensionsObjets, dimensionsEntities, objets, entites,
        typeObjet, classificationObjet, typeIndiceAptitude, typeIndiceCognitive,
        typeIndiceDangerosite, typeIndiceGlobal
    );

    return {
        dimensions: positions,
        connections,
        dimensionsObjets,
        dimensionsEntities,
        typeObjet,
        classificationObjet,
        objets,
        entites,
        typeIndiceAptitude,
        typeIndiceCognitive,
        typeIndiceDangerosite,
        typeIndiceGlobal
    };
};

export const data = initializeDimensionsAndConnections();
